import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8a880a34 = () => interopDefault(import('..\\pages\\bidding-forecast\\index.vue' /* webpackChunkName: "pages/bidding-forecast/index" */))
const _2b373820 = () => interopDefault(import('..\\pages\\business-contact\\index.vue' /* webpackChunkName: "pages/business-contact/index" */))
const _3140dbd0 = () => interopDefault(import('..\\pages\\document\\index.vue' /* webpackChunkName: "pages/document/index" */))
const _900faf58 = () => interopDefault(import('..\\pages\\home\\index.vue' /* webpackChunkName: "pages/home/index" */))
const _0b83d1a5 = () => interopDefault(import('..\\pages\\jump\\index.vue' /* webpackChunkName: "pages/jump/index" */))
const _3705f958 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _7129b400 = () => interopDefault(import('..\\pages\\msg\\index.vue' /* webpackChunkName: "pages/msg/index" */))
const _51297a7b = () => interopDefault(import('..\\pages\\notice\\index.vue' /* webpackChunkName: "pages/notice/index" */))
const _671b54ae = () => interopDefault(import('..\\pages\\portraits\\index.vue' /* webpackChunkName: "pages/portraits/index" */))
const _24c3a844 = () => interopDefault(import('..\\pages\\questionnaire\\index.vue' /* webpackChunkName: "pages/questionnaire/index" */))
const _3d61bc39 = () => interopDefault(import('..\\pages\\tou-biao-e-zhan\\index.vue' /* webpackChunkName: "pages/tou-biao-e-zhan/index" */))
const _78e4d9aa = () => interopDefault(import('..\\pages\\application\\bidding-clearing\\index.vue' /* webpackChunkName: "pages/application/bidding-clearing/index" */))
const _1a8f6374 = () => interopDefault(import('..\\pages\\application\\biddingfiletool\\index.vue' /* webpackChunkName: "pages/application/biddingfiletool/index" */))
const _c85a1e0a = () => interopDefault(import('..\\pages\\application\\ca-agent\\index.vue' /* webpackChunkName: "pages/application/ca-agent/index" */))
const _249b9b22 = () => interopDefault(import('..\\pages\\application\\credit-repair\\index.vue' /* webpackChunkName: "pages/application/credit-repair/index" */))
const _f1809132 = () => interopDefault(import('..\\pages\\application\\electronic-bidding\\index.vue' /* webpackChunkName: "pages/application/electronic-bidding/index" */))
const _af6d07b4 = () => interopDefault(import('..\\pages\\application\\epoint\\index.vue' /* webpackChunkName: "pages/application/epoint/index" */))
const _105919dd = () => interopDefault(import('..\\pages\\application\\file-agent\\index.vue' /* webpackChunkName: "pages/application/file-agent/index" */))
const _af0fb00c = () => interopDefault(import('..\\pages\\application\\file-check\\index.vue' /* webpackChunkName: "pages/application/file-check/index" */))
const _7935f7ad = () => interopDefault(import('..\\pages\\application\\file-diff\\index.vue' /* webpackChunkName: "pages/application/file-diff/index" */))
const _6028bf0a = () => interopDefault(import('..\\pages\\application\\sealed-bid-tool\\index.vue' /* webpackChunkName: "pages/application/sealed-bid-tool/index" */))
const _55b0014c = () => interopDefault(import('..\\pages\\document\\buy.vue' /* webpackChunkName: "pages/document/buy" */))
const _4a8227c6 = () => interopDefault(import('..\\pages\\document\\formPage.vue' /* webpackChunkName: "pages/document/formPage" */))
const _960b0b02 = () => interopDefault(import('..\\pages\\error\\404.vue' /* webpackChunkName: "pages/error/404" */))
const _e19ae2b8 = () => interopDefault(import('..\\pages\\home\\desktop\\index.vue' /* webpackChunkName: "pages/home/desktop/index" */))
const _2ea19e14 = () => interopDefault(import('..\\pages\\home\\download.vue' /* webpackChunkName: "pages/home/download" */))
const _b1171386 = () => interopDefault(import('..\\pages\\home\\nav\\index.vue' /* webpackChunkName: "pages/home/nav/index" */))
const _da005110 = () => interopDefault(import('..\\pages\\jump\\annual-login.vue' /* webpackChunkName: "pages/jump/annual-login" */))
const _c719663e = () => interopDefault(import('..\\pages\\jump\\out.vue' /* webpackChunkName: "pages/jump/out" */))
const _f0b4971a = () => interopDefault(import('..\\pages\\login\\frame.vue' /* webpackChunkName: "pages/login/frame" */))
const _a7edc0ce = () => interopDefault(import('..\\pages\\login\\wxLoginTransfer.vue' /* webpackChunkName: "pages/login/wxLoginTransfer" */))
const _68931e0f = () => interopDefault(import('..\\pages\\member\\pay.vue' /* webpackChunkName: "pages/member/pay" */))
const _7504be6a = () => interopDefault(import('..\\pages\\member\\pay-result.vue' /* webpackChunkName: "pages/member/pay-result" */))
const _61b2f379 = () => interopDefault(import('..\\pages\\outsys\\hebeica\\index.vue' /* webpackChunkName: "pages/outsys/hebeica/index" */))
const _05c7a14f = () => interopDefault(import('..\\pages\\outsys\\jtbidding\\index.vue' /* webpackChunkName: "pages/outsys/jtbidding/index" */))
const _074767c0 = () => interopDefault(import('..\\pages\\questionnaire\\replace.vue' /* webpackChunkName: "pages/questionnaire/replace" */))
const _f2e898d2 = () => interopDefault(import('..\\pages\\tools\\data-export\\index.vue' /* webpackChunkName: "pages/tools/data-export/index" */))
const _6f2308f3 = () => interopDefault(import('..\\pages\\user\\center\\index.vue' /* webpackChunkName: "pages/user/center/index" */))
const _5a86c81d = () => interopDefault(import('..\\pages\\application\\bidding-clearing\\frame\\index.vue' /* webpackChunkName: "pages/application/bidding-clearing/frame/index" */))
const _27fb70ba = () => interopDefault(import('..\\pages\\application\\file-diff\\frame\\index.vue' /* webpackChunkName: "pages/application/file-diff/frame/index" */))
const _423d11f8 = () => interopDefault(import('..\\pages\\outsys\\common\\feedback\\index.vue' /* webpackChunkName: "pages/outsys/common/feedback/index" */))
const _ed18e9d2 = () => interopDefault(import('..\\pages\\user\\center\\collect\\index.vue' /* webpackChunkName: "pages/user/center/collect/index" */))
const _9aa7aeaa = () => interopDefault(import('..\\pages\\user\\center\\download\\index.vue' /* webpackChunkName: "pages/user/center/download/index" */))
const _d220b576 = () => interopDefault(import('..\\pages\\user\\center\\info\\index.vue' /* webpackChunkName: "pages/user/center/info/index" */))
const _06ba3f9a = () => interopDefault(import('..\\pages\\user\\center\\order\\index.vue' /* webpackChunkName: "pages/user/center/order/index" */))
const _0e0d7571 = () => interopDefault(import('..\\pages\\user\\center\\statistic\\index.vue' /* webpackChunkName: "pages/user/center/statistic/index" */))
const _33953c23 = () => interopDefault(import('..\\pages\\application\\bidding-clearing\\frame\\list.vue' /* webpackChunkName: "pages/application/bidding-clearing/frame/list" */))
const _e66ae834 = () => interopDefault(import('..\\pages\\application\\file-diff\\frame\\list.vue' /* webpackChunkName: "pages/application/file-diff/frame/list" */))
const _9d613a4a = () => interopDefault(import('..\\pages\\outsys\\common\\feedback\\result.vue' /* webpackChunkName: "pages/outsys/common/feedback/result" */))
const _4377dff2 = () => interopDefault(import('..\\pages\\outsys\\common\\portraits\\portraits-info.vue' /* webpackChunkName: "pages/outsys/common/portraits/portraits-info" */))
const _aca391ce = () => interopDefault(import('..\\pages\\outsys\\common\\portraits\\risk-query.vue' /* webpackChunkName: "pages/outsys/common/portraits/risk-query" */))
const _3b2bfc48 = () => interopDefault(import('..\\pages\\user\\center\\download\\detail.vue' /* webpackChunkName: "pages/user/center/download/detail" */))
const _62e304a4 = () => interopDefault(import('..\\pages\\document\\_guid.vue' /* webpackChunkName: "pages/document/_guid" */))
const _38586611 = () => interopDefault(import('..\\pages\\notice\\_guid.vue' /* webpackChunkName: "pages/notice/_guid" */))
const _98bd7d82 = () => interopDefault(import('..\\pages\\portraits\\_guid.vue' /* webpackChunkName: "pages/portraits/_guid" */))
const _607adcd3 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _334c5932 = () => interopDefault(import('..\\pages\\error\\404' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bidding-forecast",
    component: _8a880a34,
    alias: ["/bidding-forecast/index.html"],
    name: "bidding-forecast"
  }, {
    path: "/business-contact",
    component: _2b373820,
    alias: ["/business-contact/index.html"],
    name: "business-contact"
  }, {
    path: "/document",
    component: _3140dbd0,
    alias: ["/document/index.html"],
    name: "document"
  }, {
    path: "/home",
    component: _900faf58,
    alias: ["/home/index.html"],
    name: "home"
  }, {
    path: "/jump",
    component: _0b83d1a5,
    alias: ["/jump/index.html"],
    name: "jump"
  }, {
    path: "/login",
    component: _3705f958,
    alias: ["/login/index.html"],
    name: "login"
  }, {
    path: "/msg",
    component: _7129b400,
    alias: ["/msg/index.html"],
    name: "msg"
  }, {
    path: "/notice",
    component: _51297a7b,
    alias: ["/notice/index.html"],
    name: "notice"
  }, {
    path: "/portraits",
    component: _671b54ae,
    alias: ["/portraits/index.html"],
    name: "portraits"
  }, {
    path: "/questionnaire",
    component: _24c3a844,
    alias: ["/questionnaire/index.html"],
    name: "questionnaire"
  }, {
    path: "/tou-biao-e-zhan",
    component: _3d61bc39,
    alias: ["/tou-biao-e-zhan/index.html"],
    name: "tou-biao-e-zhan"
  }, {
    path: "/application/bidding-clearing",
    component: _78e4d9aa,
    alias: ["/application/bidding-clearing/index.html"],
    name: "application-bidding-clearing"
  }, {
    path: "/application/biddingfiletool",
    component: _1a8f6374,
    alias: ["/application/biddingfiletool/index.html"],
    name: "application-biddingfiletool"
  }, {
    path: "/application/ca-agent",
    component: _c85a1e0a,
    alias: ["/application/ca-agent/index.html"],
    name: "application-ca-agent"
  }, {
    path: "/application/credit-repair",
    component: _249b9b22,
    alias: ["/application/credit-repair/index.html"],
    name: "application-credit-repair"
  }, {
    path: "/application/electronic-bidding",
    component: _f1809132,
    alias: ["/application/electronic-bidding/index.html"],
    name: "application-electronic-bidding"
  }, {
    path: "/application/epoint",
    component: _af6d07b4,
    alias: ["/application/epoint/index.html"],
    name: "application-epoint"
  }, {
    path: "/application/file-agent",
    component: _105919dd,
    alias: ["/application/file-agent/index.html"],
    name: "application-file-agent"
  }, {
    path: "/application/file-check",
    component: _af0fb00c,
    alias: ["/application/file-check/index.html"],
    name: "application-file-check"
  }, {
    path: "/application/file-diff",
    component: _7935f7ad,
    alias: ["/application/file-diff/index.html"],
    name: "application-file-diff"
  }, {
    path: "/application/sealed-bid-tool",
    component: _6028bf0a,
    alias: ["/application/sealed-bid-tool/index.html"],
    name: "application-sealed-bid-tool"
  }, {
    path: "/document/buy",
    component: _55b0014c,
    alias: ["/document/buy/index.html"],
    name: "document-buy"
  }, {
    path: "/document/formPage",
    component: _4a8227c6,
    alias: ["/document/formPage/index.html"],
    name: "document-formPage"
  }, {
    path: "/error/404",
    component: _960b0b02,
    alias: ["/error/404/index.html"],
    name: "error-404"
  }, {
    path: "/home/desktop",
    component: _e19ae2b8,
    alias: ["/home/desktop/index.html"],
    name: "home-desktop"
  }, {
    path: "/home/download",
    component: _2ea19e14,
    alias: ["/home/download/index.html"],
    name: "home-download"
  }, {
    path: "/home/nav",
    component: _b1171386,
    alias: ["/home/nav/index.html"],
    name: "home-nav"
  }, {
    path: "/jump/annual-login",
    component: _da005110,
    alias: ["/jump/annual-login/index.html"],
    name: "jump-annual-login"
  }, {
    path: "/jump/out",
    component: _c719663e,
    alias: ["/jump/out/index.html"],
    name: "jump-out"
  }, {
    path: "/login/frame",
    component: _f0b4971a,
    alias: ["/login/frame/index.html"],
    name: "login-frame"
  }, {
    path: "/login/wxLoginTransfer",
    component: _a7edc0ce,
    alias: ["/login/wxLoginTransfer/index.html"],
    name: "login-wxLoginTransfer"
  }, {
    path: "/member/pay",
    component: _68931e0f,
    alias: ["/member/pay/index.html"],
    name: "member-pay"
  }, {
    path: "/member/pay-result",
    component: _7504be6a,
    alias: ["/member/pay-result/index.html"],
    name: "member-pay-result"
  }, {
    path: "/outsys/hebeica",
    component: _61b2f379,
    alias: ["/outsys/hebeica/index.html"],
    name: "outsys-hebeica"
  }, {
    path: "/outsys/jtbidding",
    component: _05c7a14f,
    alias: ["/outsys/jtbidding/index.html"],
    name: "outsys-jtbidding"
  }, {
    path: "/questionnaire/replace",
    component: _074767c0,
    alias: ["/questionnaire/replace/index.html"],
    name: "questionnaire-replace"
  }, {
    path: "/tools/data-export",
    component: _f2e898d2,
    alias: ["/tools/data-export/index.html"],
    name: "tools-data-export"
  }, {
    path: "/user/center",
    component: _6f2308f3,
    alias: ["/user/center/index.html"],
    name: "user-center"
  }, {
    path: "/application/bidding-clearing/frame",
    component: _5a86c81d,
    alias: ["/application/bidding-clearing/frame/index.html"],
    name: "application-bidding-clearing-frame"
  }, {
    path: "/application/file-diff/frame",
    component: _27fb70ba,
    alias: ["/application/file-diff/frame/index.html"],
    name: "application-file-diff-frame"
  }, {
    path: "/outsys/common/feedback",
    component: _423d11f8,
    alias: ["/outsys/common/feedback/index.html"],
    name: "outsys-common-feedback"
  }, {
    path: "/user/center/collect",
    component: _ed18e9d2,
    alias: ["/user/center/collect/index.html"],
    name: "user-center-collect"
  }, {
    path: "/user/center/download",
    component: _9aa7aeaa,
    alias: ["/user/center/download/index.html"],
    name: "user-center-download"
  }, {
    path: "/user/center/info",
    component: _d220b576,
    alias: ["/user/center/info/index.html"],
    name: "user-center-info"
  }, {
    path: "/user/center/order",
    component: _06ba3f9a,
    alias: ["/user/center/order/index.html"],
    name: "user-center-order"
  }, {
    path: "/user/center/statistic",
    component: _0e0d7571,
    alias: ["/user/center/statistic/index.html"],
    name: "user-center-statistic"
  }, {
    path: "/application/bidding-clearing/frame/list",
    component: _33953c23,
    alias: ["/application/bidding-clearing/frame/list/index.html"],
    name: "application-bidding-clearing-frame-list"
  }, {
    path: "/application/file-diff/frame/list",
    component: _e66ae834,
    alias: ["/application/file-diff/frame/list/index.html"],
    name: "application-file-diff-frame-list"
  }, {
    path: "/outsys/common/feedback/result",
    component: _9d613a4a,
    alias: ["/outsys/common/feedback/result/index.html"],
    name: "outsys-common-feedback-result"
  }, {
    path: "/outsys/common/portraits/portraits-info",
    component: _4377dff2,
    alias: ["/outsys/common/portraits/portraits-info/index.html"],
    name: "outsys-common-portraits-portraits-info"
  }, {
    path: "/outsys/common/portraits/risk-query",
    component: _aca391ce,
    alias: ["/outsys/common/portraits/risk-query/index.html"],
    name: "outsys-common-portraits-risk-query"
  }, {
    path: "/user/center/download/detail",
    component: _3b2bfc48,
    alias: ["/user/center/download/detail/index.html"],
    name: "user-center-download-detail"
  }, {
    path: "/document/:guid",
    component: _62e304a4,
    alias: ["/document/:guid/index.html"],
    name: "document-guid"
  }, {
    path: "/notice/:guid",
    component: _38586611,
    alias: ["/notice/:guid/index.html"],
    name: "notice-guid"
  }, {
    path: "/portraits/:guid",
    component: _98bd7d82,
    alias: ["/portraits/:guid/index.html"],
    name: "portraits-guid"
  }, {
    path: "/",
    component: _607adcd3,
    alias: ["/index.html"],
    name: "index"
  }, {
    path: "*",
    component: _334c5932,
    name: "404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
